"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.templates = void 0;
const escapeHTML_1 = require("./escapeHTML");
exports.templates = {
    algolia: (hostname) => {
        const escapedHostname = escapeHTML_1.escapeHTML(hostname);
        return `
      <a
        href="https://www.algolia.com/?utm_source=netlify&utm_medium=link&utm_campaign=autocomplete-${escapedHostname}"
        class="aa-powered-by-link"
      >
        Algolia
      </a>
    `;
    },
    autocomplete: {
        css: (color) => `
      .aa-hit--highlight {
        color: ${color};
      }

      .aa-hit--title .aa-hit--highlight::before {
        background-color: ${color};
      }
    `,
        poweredBy: (algoliaLogoHtml) => `
      <div class="aa-powered-by">
        Search by ${algoliaLogoHtml}
      </div>`,
        suggestion: (hit) => `
      <div class="aa-hit aa-hit__${hit.sizeModifier}">
        <div class="aa-hit--title">${hit._highlightResult.title.value}</div>
        <div class="aa-hit--description">${hit.snippet}</div>
      </div>
    `,
    },
};
