"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlgoliasearchNetlify = void 0;
const AutocompleteWrapper_1 = require("./AutocompleteWrapper");
const defaultOptions = {
    analytics: true,
    autocomplete: {
        hitsPerPage: 5,
        inputSelector: 'input[type=search]',
    },
    color: '#3c4fe0',
    debug: false,
    silenceWarnings: false,
    poweredBy: true,
};
class AlgoliasearchNetlify {
    constructor(_options) {
        AlgoliasearchNetlify.instances.push(this);
        // Temporary
        const splitIndexName = (indexName) => {
            const regexp = /^netlify_([0-9a-f-]+)_(.*)_all$/;
            const [, siteId, branch] = indexName.match(regexp);
            return { siteId, branch };
        };
        // eslint-disable-next-line no-warning-comments
        // TODO: add validation
        const options = Object.assign(Object.assign(Object.assign(Object.assign({}, defaultOptions), _options), (_options.indexName && splitIndexName(_options.indexName))), { autocomplete: Object.assign(Object.assign({}, defaultOptions.autocomplete), _options.autocomplete) });
        this.search = new AutocompleteWrapper_1.AutocompleteWrapper(options);
        // Wait for DOM initialization, then render
        const render = this.render.bind(this, options);
        if (['complete', 'interactive'].includes(document.readyState)) {
            render();
        }
        else {
            document.addEventListener('DOMContentLoaded', render);
        }
    }
    render(options) {
        this.search.render(options);
    }
}
exports.AlgoliasearchNetlify = AlgoliasearchNetlify;
AlgoliasearchNetlify.instances = [];
