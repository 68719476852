"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addCss = void 0;
function addCss(css, $mainStyle = null) {
    var _a;
    const $usedSibling = (_a = $mainStyle !== null && $mainStyle !== void 0 ? $mainStyle : document.querySelector('link[rel=stylesheet][href*="algoliasearchNetlify"]')) !== null && _a !== void 0 ? _a : document.getElementsByTagName('head')[0].lastChild;
    const $styleTag = document.createElement('style');
    $styleTag.setAttribute('type', 'text/css');
    $styleTag.appendChild(document.createTextNode(css));
    return $usedSibling.parentNode.insertBefore($styleTag, $usedSibling.nextSibling);
}
exports.addCss = addCss;
